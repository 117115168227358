import * as React from "react"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Seo from "../components/seo"

const PolicyPage = () => (
  <Layout>
    <Spacer />
  </Layout>
)

export default PolicyPage

export const Head = () => <Seo title="Privacy Policy" />
